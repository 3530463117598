//GA dataLayer - Kliknięcie na link z formulrzem zwrotu i reklamacji
(function ($) {
    $(".js-complaint-and-return-form").on("click", function (e) {
        e.preventDefault();

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: "returnFormDownload",
        });

        window.open($(this).attr('href'));
    });
})(jQuery);
