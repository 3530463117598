(function ($) {
    $(document).on("click", ".js-product-variation-switch", function (e) {
        const element_tag_to_display =
            e.currentTarget.dataset.target.substring(1);
        $(".product-tags").removeClass("product-tags--active");
        $(`#js-${element_tag_to_display}-product-tag`).addClass(
            "product-tags--active"
        );


        $('.js-product-variation-switch').parent().removeClass("product-tabs__tab--active");
        $(this).parent().addClass('product-tabs__tab--active');


        if (element_tag_to_display === "audiobook") {
            $(".js-audiobook-summary").addClass("audiobook-summary--active");
        } else {
            $(".js-audiobook-summary").removeClass("audiobook-summary--active");
        }
    });

    
})(jQuery);
