jQuery(function ($) {

    $.scroll_to_notices = function (scrollElement) {
        if (scrollElement.length) {
            $('html, body').animate({
                scrollTop: (scrollElement.offset().top - 150)
            }, 1000);
        }
    };

});