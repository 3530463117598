(function ($) {
    $(document).on("change", ".js-submit-on-change", function () {
        var $form = $(this).closest("form");
        $form.trigger("submit");
    });

    $(document).on("submit", "#js-products-form-noajax", function (e) {
        $("html, body").animate({ scrollTop: 0 }, 400);
        $("#js-products-loop").html("Ładuję książki...");
    });
})(jQuery);
