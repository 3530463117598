(function ($) {
    //Close search menu when is open while trying to open nabar
    $(document).on("click", ".js-navbar-toggler", () => {
        const $searchToggler = $(".js-search-toggler");
        const isSearchCollapsed = $searchToggler.hasClass("collapsed");
        const $searchBar = $("#js-top-search-input");
        const $searchBarWNS = $("#js-wns-top-search-input");

        if (isSearchCollapsed) return;

        $searchToggler.trigger("click");
        $searchBar.trigger("blur");
        $searchBarWNS.trigger("blur");
    });

    //Close navbar when is open while trying to open search menu
    $(document).on("click", ".js-search-toggler", () => {
        const $navBarToggler = $(".js-navbar-toggler");
        const isNavBarCollapsed = $navBarToggler.hasClass("collapsed");

        if (isNavBarCollapsed) return;

        $navBarToggler.trigger("click");
    });

    //focus on search bar when clicking on open search menu button
    $(document).on("click", ".js-search-toggler", () => {
        const $searchBar = $("#js-top-search-input");
        const $searchBarWNS = $("#js-wns-top-search-input");

        $searchBar.trigger("focus");
        $searchBarWNS.trigger("focus");
        $searchBarWNS.trigger("click");
    });
})(jQuery);
