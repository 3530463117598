(function ($) {
  /**
   * Funkcja zarządzająca płynnym scroll'em po całej stronie.
   * @version 3.0.0
   */
  $(document).on("click", "a.smooth-scroll", function (e) {
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      var target = this.hash;
      var $target = $(target);

      $target = $target.length
        ? $target
        : $("[name=" + this.hash.slice(1) + "]");

      if ($target.length) {
        let fromTop = $target.offset().top;
        let scrollToOffset = $("#siteHeader").innerHeight();
        e.preventDefault();
        $("html, body").animate(
          {
            scrollTop: fromTop - scrollToOffset,
          },
          500,
          function () {
            if (history.pushState) {
              history.pushState(null, null, target);
            } else {
              window.location.hash = target;
            }
          }
        );
      }
    }
  });

  // Prevent default hash jump - TODO: CRON PSUJE, narazie off
  //   var target = window.location.hash;
  //   if (target) {
  //     let $target = $(target);
  //     if ($target.length > 0) {
  //       window.location.hash = "";
  //     }
  //   }
  //   $(window).on("load", function () {
  //     setTimeout(function () {
  //       if (target) {
  //         let $target = $(target);

  //         if ($target.length > 0) {
  //           let scrollToOffset = $("#siteHeader").innerHeight();

  //           let fromTop = $target.offset().top;
  //           $("html, body").animate(
  //             {
  //               scrollTop: fromTop - scrollToOffset,
  //             },
  //             250,
  //             function () {
  //               if (history.pushState) {
  //                 history.pushState(null, null, target);
  //               } else {
  //                 window.location.hash = target;
  //               }
  //             }
  //           );

  //           $target.trigger('focus');
  //           if ($target.is(":focus")) {
  //             // Checking if the target was focused
  //             return false;
  //           } else {
  //             $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
  //             $target.focus(); // Set focus again
  //           }
  //         }
  //       }
  //     }, 100);
  //   });
})(jQuery);
