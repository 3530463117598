(function ($) {
    /**
     * share dropup
     */
    var dropup_trigger = ".js-dropup-share-trigger",
        dropup = ".js-dropup-share";

    $(dropup_trigger).hover(
        function () {
            console.log('test');
            $(this).find(dropup).addClass("is-shown");
        },
        function () {
            $(this).find(dropup).removeClass("is-shown");
        }
    );
})(jQuery);
