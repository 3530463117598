(function ($) {
    // Maski na pola
    $(document).on("ready", function () {
        $(".js-mask-postal-code").mask("00-000");
        $(".js-mask-tel").mask("000000000");
    });

    $(document).on("focus", ".mda-field-error input", function () {
        $input = $(this);
        // if ($input.hasClass('js-validate-on-fly')) return;
        $inputWrapper = $input.parent().parent();
        $inputWrapper.removeClass("mda-field-error");
        $input.next(".js-checkout-validation-response").remove();
    });

    // Walidacja pojedynczego pola
    $(".js-validate-on-fly").on("blur", function () {
        $input = $(this);
        $inputWrapper = $input.parent().parent();
        $inputWrapper.removeClass("mda-field-error");
        $input.next(".js-checkout-validation-response").remove();

        $.ajax({
            url: ajax.url,
            data: {
                action: "mda_validate_on_fly",
                key: $input.attr("name"),
                val: $input.val(),
            },
            type: "post",
            success: function (response) {
                if (response) {
                    response = JSON.parse(response);
                    if (response && response.status == "ERROR") {
                        $input = $(`input[name="${response.key}"]`);
                        $inputWrapper = $input.parent().parent();

                        $inputWrapper.addClass("mda-field-error");

                        // $inputWrapper.removeClass('mda-field-ok');
                        $(
                            '<p class="js-checkout-validation-response input__error">' +
                                response.msg +
                                "</p>"
                        ).insertAfter($input);
                        // GA dataLayer - Błąd w polu formularza
                        var formName = $input
                            .parents("form[data-form-name]")
                            .attr("data-form-name");
                        var inputName = $input.attr("name");
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            event: "formError",
                            formName: formName,
                            fieldName: inputName,
                            errorCount: "1",
                        });
                    } else {
                        $inputWrapper.removeClass("mda-field-error");
                        $input
                            .next(".js-checkout-validation-response")
                            .remove();
                    }
                }
            },
        });
    });

    // Walidacja formularza
    $(document).on("click", "#js-checkout-form #place_order", function (e) {
        $checkoutForm = $(document).find("#js-checkout-form");

        e.preventDefault();
        var data = $checkoutForm.serialize();
        data += "&action=mda_validate_checkout_form";
        $(".woocommerce-NoticeGroup").remove();

        $.ajax({
            url: ajax.url,
            data: data,
            type: "post",
            success: function (response) {
                if (response) {
                    response = JSON.parse(response);

                    if (response && response.status == "SUCCESS") {
                        $(document)
                            .find(".js-checkout-validation-response")
                            .remove();
                        // $('.input--validate-text:not(.mda-field-error)').addClass('mda-field-ok');

                        $checkoutForm.submit();
                    } else {
                        $(document)
                            .find(".js-checkout-validation-response")
                            .remove();
                        $("#js-form-notice-wrapper").html(
                            '<div class="woocommerce-error">' +
                                response.msg +
                                "</div>"
                        );

                        $.each(response.errors.fields, function (key, value) {
                            $input = $checkoutForm.find('[name="' + key + '"]');
                            $inputWrapper = $input.parent().parent();
                            $inputWrapper.addClass("mda-field-error");
                            $(
                                '<p class="js-checkout-validation-response input__error">' +
                                    value +
                                    "</p>"
                            ).insertAfter($input);
                            // GA dataLayer - Błąd w polu formularza
                            var formName = $checkoutForm.attr("data-form-name");
                            var inputName = $input.attr("name");
                            window.dataLayer = window.dataLayer || [];
                            dataLayer.push({
                                event: "formError",
                                formName: formName,
                                fieldName: inputName,
                                errorCount: "1",
                            });
                        });

                        // $('.input--validate-text:not(.mda-field-error)').addClass('mda-field-ok');

                        $("html, body").animate(
                            {
                                scrollTop:
                                    $("#js-form-notice-wrapper").offset().top -
                                    150,
                            },
                            1000
                        );
                    }
                } else {
                    $(".js-checkout-validation-response").remove();
                    $checkoutForm.submit();
                }
            },
        });
    });

    // Pokaż lub ukryj pola firmowe przy zmianie input
    $(document).on("change", 'input[name="is_company"]', function () {
        if ($(this).val() == 1) {
            $(document)
                .find(".js-checkout-company-input")
                .attr("disabled", false);
            $(".js-company-fields").removeClass("d-none");
        } else {
            $(document)
                .find(".js-checkout-company-input")
                .attr("disabled", true);
            $(".js-company-fields").addClass("d-none");
        }
    });

    // Pokaż lub ukryj pola firmowe przy załadowaniu dokumentu
    $(document).on("ready", function () {
        if ($('[name="is_company"]').val() == 1) {
            $(document)
                .find(".js-checkout-company-input")
                .attr("disabled", false);
            $(".js-company-fields").removeClass("d-none");
        } else {
            $(document)
                .find(".js-checkout-company-input")
                .attr("disabled", true);
            $(".js-company-fields").addClass("d-none");
        }
    });

    // Obsługa shipping method
    $(document.body).on("update_checkout", function (e) {
        setShippingMethodIntegration();
    });

    function setShippingMethodIntegration() {
        var $checked = $(".js-shipping-method-selector:checked");
        var $input = $('[name="shipping_method_integration"]');

        if ($checked.length > 0 && $input.length > 0) {
            var integration = $checked.attr("mda-shipping-method-integration");
            $input.val(integration);

            if (integration == "mda_paczkomaty") {
                $('[name="mda_paczkomaty_paczkomat_id"]').removeAttr(
                    "disabled"
                );
            } else {
                $('[name="mda_paczkomaty_paczkomat_id"]').attr(
                    "disabled",
                    "disabled"
                );
            }
        }
    }
})(jQuery);
