//GA dataLayer - Usunięcie produktu z koszyka
(function ($) {
    const catalogues_iframes = $(".catalogues__catalogue-iframe");
    const catalogues_description = $(".catalogue-description__wrapper");

    $(document).on("click", ".js-catalogue-box-link", function (e) {
        const catalogue_index = parseInt(
            e.currentTarget.dataset.catalogueIndex
        );

        $(catalogues_iframes).hide();

        $(catalogues_description).hide();

        catalogues_iframes[catalogue_index].style.display = "block";
        catalogues_description[catalogue_index].style.display = "flex";
    });
})(jQuery);
