(function ($) {
    var $form = $("#js-products-form");

    $(document).on("change", ".js-submit-product-form-on-change", function () {
        $paged = $form.find("#js-product-paged").val(1);

        $form.submit();
    });

    $(document).on("click", ".js-ajax-pagination a", function (e) {
        e.preventDefault();
        $paged = $form.find("#js-product-paged").val($(this).attr("data-page"));

        $form.submit();
    });

    $(document).on("submit", "#js-products-form", function (e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 400);

        $.ajax({
            url: ajax.url,
            data: $form.serialize(),
            type: "GET",
            beforeSend: function () {
                $("#js-products-loop").html("Ładuję książki...");
            },
            success: function (response) {
                window.history.pushState({}, null, response.url);
                $("#js-products-loop").html(response.html);
                $("#js-sidebar-filter-ajax").html(response.filters_html);

                if (
                    response.filters_html === undefined ||
                    response.filters_html === null
                )
                    return;

                reInitRanges();
            },
        });
    });

    function reInitRanges() {
        /**
         * price range
         */
        var rangeSlider = $("#js-price-slider-range");
        var rangeSliderInputMin = $("#js-amount-min");
        var rangeSliderInputMax = $("#js-amount-max");
        var rangeMin = 0;
        var rangeMax = parseInt(rangeSliderInputMax.attr("data-max")) + 1;

        function priceRangeInputs(
            rangeSlider,
            rangeSliderInputMin,
            rangeSliderInputMax,
            rangeMin,
            rangeMax
        ) {
            rangeSlider.slider({
                range: true,
                min: rangeMin,
                max: rangeMax,
                values: [rangeSliderInputMin.val(), rangeSliderInputMax.val()],
                slide: function (event, ui) {
                    rangeSliderInputMin.val(ui.values[0]);
                    rangeSliderInputMax.val(ui.values[1]);
                },
                stop: function (event, ui) {
                    if ($("#js-products-form").length > 0) {
                        $("#js-products-form").trigger("submit");
                    }

                    if ($("#js-products-form-noajax").length > 0) {
                        $("#js-products-form-noajax").trigger("submit");
                    }

                    if (window.innerWidth < 1024) {
                        $(".js-book-filter-toggler").trigger("click");
                    }
                },
            });
            rangeSliderInputMin.val(rangeSlider.slider("values", 0));
            rangeSliderInputMax.val(rangeSlider.slider("values", 1));

            rangeSliderInputMin.change(function () {
                rangeSlider.slider("option", "values", [
                    rangeSliderInputMin.val(),
                    rangeSliderInputMax.val(),
                ]);

                if (rangeSliderInputMin.val() < rangeMin) {
                    rangeSliderInputMin.val(rangeMin);
                    rangeSliderInputMin.trigger("change");
                }

                if (rangeSliderInputMin.val() > rangeMax) {
                    rangeSliderInputMin.val(rangeSlider.slider("values", 1));
                    rangeSliderInputMin.trigger("change");
                }

                if (
                    rangeSliderInputMin.val() > rangeSlider.slider("values", 1)
                ) {
                    rangeSliderInputMin.val(rangeSlider.slider("values", 1));
                    rangeSliderInputMin.trigger("change");
                }
            });

            rangeSliderInputMax.change(function () {
                rangeSlider.slider("option", "values", [
                    rangeSliderInputMin.val(),
                    rangeSliderInputMax.val(),
                ]);

                if (rangeSliderInputMax.val() < rangeMin) {
                    rangeSliderInputMax.val(rangeSlider.slider("values", 0));
                    rangeSliderInputMax.trigger("change");
                }

                if (rangeSliderInputMax.val() > rangeMax) {
                    rangeSliderInputMax.val(rangeMax);
                    rangeSliderInputMax.trigger("change");
                }

                if (
                    rangeSliderInputMax.val() < rangeSlider.slider("values", 0)
                ) {
                    rangeSliderInputMax.val(rangeSlider.slider("values", 0));
                    rangeSliderInputMax.trigger("change");
                }
            });
        }

        priceRangeInputs(
            rangeSlider,
            rangeSliderInputMin,
            rangeSliderInputMax,
            parseInt(rangeMin),
            parseInt(rangeMax)
        );
    }
})(jQuery);
