(function ($) {

    var $booksContainer = $('.js-bar-with-books-container'),
        $link = $('.js-bar-with-books-link');

    $('.js-bar-with-books-categories [data-term-id]').on('click', function () { 
        $('.js-bar-with-books-categories [data-term-id]').removeClass('is-active').addClass('is-disabled');
        fetchBooks($(this).attr('data-term-id'));
        $(this).addClass('is-clicked');
        $booksContainer.addClass('is-loading');
    });

    function fetchBooks(termId) { 
        $.ajax({
            type: 'post',
            url: ajax.url,
            data: {
                action: 'mda_fetch_books',
                term_id: termId,
                security: ajax.nonce
            },
            beforeSend: function () {
                // $booksContainer.html('Pobieram książki...');
            },
            success: function (response) {
                var response = $.parseJSON(response);
                $booksContainer.html(response.html);
                $link.attr('href', response.url);
                $('.js-bar-with-books-categories-label').text(response.cat_name);
                $('.js-bar-with-books-categories [data-term-id].is-disabled').removeClass('is-disabled');
                $('.js-bar-with-books-categories [data-term-id].is-clicked').removeClass('is-clicked').addClass('is-active');
                $booksContainer.removeClass('is-loading');
            }
        });
    }

})(jQuery);