(function ($) {
  /**
   * wyrównanie wysokości
   */
  $(".match-height").matchHeight();

  /**
   * select2
   */
  $(".js-select2-small").select2({
    minimumResultsForSearch: -1,
    theme: "small",
  });

  $(".js-select2-search").select2({
    theme: "default",
    placeholder: function () {
      $(this).data("placeholder");
    },
    allowClear: true,
  });

  $(".js-select2-default").select2({
    theme: "default",
    minimumResultsForSearch: -1,
    placeholder: function () {
      $(this).data("placeholder");
    },
    allowClear: true,
  });

  /**
   * powiązane ze scrollem
   */
  $(window).scroll(function () {
    var scroll = $(window).scrollTop(),
      newsletter = $("#newsletterInfo"),
      nav = $("#navHeader");

    if (scroll >= 20) {
      newsletter.addClass("social-media__link--newsletter-scroll");
    } else {
      newsletter.removeClass("social-media__link--newsletter-scroll");
    }
  });

  /**
   * kolorowanie tekstu na bannerze
   */
  var bannerInfoHeadline = $("#bannerInfoHeadline");
  if (bannerInfoHeadline.length != "undefined") {
    var color = bannerInfoHeadline.data("accent");
    bannerInfoHeadline.find("strong").css("color", color);
  }

  /**
   * scroll to fix
   */
  $(document).on('ready', function () {
    $("#js-float-sidebar").scrollToFixed({
      marginTop: 150,
      limit: function () {
        var limit =
          $("#js-float-sidebar-limit").offset().top -
          $("#js-float-sidebar").outerHeight(true);
        return limit;
      },
      removeOffsets: true,
    });
  });

  /**
   * Opis kategorii
   */


  $(document).on('ready', function () {
    var content = $('.content-read-more');
    var fullText = $('.full-text');
    var truncatedText = $('.truncated-text');
    var readMoreBtn = $('.content-read-more__button');
    var buttonArrow = $('.content-read-more__arrow');
    // content.css('max-height', '200px');
    // content.css('overflow', 'hidden');
    // truncatedText.text(fullText.text().substring(0, 200) + "...");
    fullText.hide();
    truncatedText.show();
    readMoreBtn.on('click', function () {
      if (readMoreBtn.text() == 'Czytaj więcej') {
        fullText.show();
        truncatedText.hide();
        // content.css('max-height', '3000px');
        readMoreBtn.text('Czytaj mniej');
        buttonArrow.addClass('content-read-more__arrow--less');
      } else {
        fullText.hide();
        truncatedText.show();
        readMoreBtn.text('Czytaj więcej');
        // content.css('max-height', '200px');
        buttonArrow.removeClass('content-read-more__arrow--less');
      }
    });
  });

  $(document).on('ready', function () {
    var content_product = $('.content-read-more-product');
    var fullText_product = $('.full-text-product');
    var truncatedText_product = $('.truncated-text-product');
    var readMoreBtn_product = $('.content-read-more-product__button');
    var buttonArrow_product = $('.content-read-more-product__arrow');
    // content_product.css('max-height', '200px');
    // content_product.css('overflow', 'hidden');
    // truncatedText_product.text(fullText_product.text().substring(0, 200) + "...");
    fullText_product.hide();
    truncatedText_product.show();
    readMoreBtn_product.on('click', function () {
      if (readMoreBtn_product.text() == 'Czytaj więcej') {
        fullText_product.show();
        truncatedText_product.hide();
        // content_product.css('max-height', '3000px');
        readMoreBtn_product.text('Czytaj mniej');
        buttonArrow_product.addClass('content-read-more-product__arrow--less');
      } else {
        fullText_product.hide();
        truncatedText_product.show();
        readMoreBtn_product.text('Czytaj więcej');
        // content_product.css('max-height', '200px');
        buttonArrow_product.removeClass('content-read-more-product__arrow--less');
      }
    });
  });

  /**
   * Check if user is leaving cart 
   */

  $(document).on('ready', function () {
    $(window).on('beforeunload', function () {
      // Check if the current page is the cart page
      if ($('body').hasClass('woocommerce-cart')) {
        // Send an AJAX request to the server to log the cart abandonment
        $.ajax({
          url: ajax.url,
          data: {
            action: 'log_cart_abandonment'
          },
          type: 'post',
          success: function (response) {
            console.log('Cart abandonment logged.');
          },
          error: function (error) {
            console.log(error);
          }
        });
      }
    });
  });


  /**
   * Popstate fix
   */
  $(window).on("popstate", function (e) {
    if (e.state == null) {
      location.reload();
    }
  });

  /**
   * Tooltipy
   */

  $('[data-toggle="tooltip"]').tooltip();
  
})(jQuery);
