(function ($) {
    var $form = $("#js-top-search-form"),
        $input = $("#js-top-search-input"),
        $hints = $("#js-top-search-hints");

    $(".js-custom-scrollbar-dark").mCustomScrollbar({ alwaysShowScrollbar: 1 });

    function initHints(e) {
        var that = this,
            input_text = $(that).val();

        /**
         * ukryj jeśli puste
         */
        if (input_text != "" && input_text.length > 2) {
            $hints.addClass("active");
            $(".js-search-input-wrapper").addClass("-active");
        } else {
            $hints.removeClass("active").html("");
            $(".js-search-input-wrapper").removeClass("-active");
        }

        /**
         * pokaż/ukryj na blur
         */
        $input.focus(function () {
            input_text = $input.val();
            if (input_text != "" && input_text.length > 2) {
                $hints.addClass("active");
                $(".js-search-input-wrapper").addClass("-active");
            }
        });

        $(document).on("click", function (e) {
            if (
                $(e.target).closest($hints).length === 0 &&
                $(e.target).closest($form).length === 0 &&
                $(e.target).closest($input).length === 0
            ) {
                $hints.removeClass("active");
                $(".js-search-input-wrapper").removeClass("-active");
            }
        });

        $hints.addClass("pending");

        $.ajax({
            url: ajax.url,
            data: {
                action: "search_hints",
                value: input_text,
            },
            type: "post",
            success: function (data) {
                $hints.removeClass("pending");
                data = JSON.parse(data);
                $hints.html(data.books);
                $(".js-custom-scrollbar-dark").mCustomScrollbar({
                    alwaysShowScrollbar: 1,
                });
            },
        });

        return false;
    }

    //init hints
    $input.on("keyup", _.debounce(initHints, 500));
})(jQuery);
