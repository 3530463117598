(function ($) {
    var $product_tabs = $('#product-type-tabs');
    if ($product_tabs.length > 0) { 
        $('#product-type-tabs a').on('click', function (e) {
            e.preventDefault();
            var new_url = $(this).attr('href');
            $(this).tab('show');
            window.history.replaceState("", document.title, new_url)
        });
    }
})(jQuery);