(function ($) {
    $(document).on('ready', function () {
        $(document).on('click', '.js-quantity .js-plus', function (e) {
            $input = $(this).prev('input.js-qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof step ? parseInt(step) : 1;
            $input.val(val + step).change();
        });
        $(document).on('click', '.js-quantity .js-minus', function (e) {
            $input = $(this).next('input.js-qty');
            var val = parseInt($input.val());
            var step = $input.attr('step');
            step = 'undefined' !== typeof step ? parseInt(step) : 1;
            if (val > 1) {
                $input.val(val - step).change();
            }
        });

        $(document).on('change', 'input.js-qty', function (e) {
            console.log('change');
            $("[name='update_cart']").removeAttr('disabled');
            $("[name='update_cart']").trigger('click');
        });
    });
})(jQuery);
