(function ($) {
    /**
     * Shops map
     */
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    var paczkomatyMap;
    var markerArray = [];

    var mapMarkers = L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
    });

    var zoom = {
        min: 5,
        max: 18,
        start: 7,
        goto: 18,
        search: 16
    };

    var pinBlue = L.icon({
        iconUrl: mda_theme_url + '/dist/img/leaflet/marker-icon.png',
        iconRetinaUrl: mda_theme_url + '/dist/img/leaflet/marker-icon-2x.png',
        iconSize: [23, 32],
        iconAnchor: [12, 32],
    });

    var pinRed = L.icon({
        iconUrl: mda_theme_url + '/dist/img/leaflet/marker-icon-red.png',
        iconRetinaUrl:
            mda_theme_url + '/dist/img/leaflet/marker-icon-red-2x.png',
        iconSize: [23, 32],
        iconAnchor: [12, 32],
    });

    // Initilize map
    function initMap(id, startPoint = false) {
        var _map = new L.Map(id, {
            zoomControl: true,
            doubleClickZoom: true
        });

        var osm = new L.TileLayer(
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            {
                minZoom: zoom.min,
                maxZoom: zoom.max,
                attribution:
                    'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
            }
        );

        _map.setView(new L.LatLng(52.0693267, 19.4781225), zoom.start);

        _map.addLayer(osm);

        $('.js-mda-paczkomaty-map-loader').addClass('d-none');

        return _map;
    }

    function setupMarkers(map, markersJson, center) {
        var $map = $('#' + map._container.id);

        if ($map.length > 0 && map !== null) {
            mapMarkers.clearLayers();

            $('.js-mda-paczkomaty-map-loader').removeClass('d-none');

            if (markersJson.length > 0) {

                // Dodanie pinów
                markersJson.forEach(function (item) {
                    if (item.location_latitude && item.location_longitude) {
                        var marker = L.marker(
                            [item.location_latitude, item.location_longitude],
                            {
                                icon: pinBlue,
                            }
                        );

                        marker.paczkomat_id = item.ID;

                        // Popup
                        var markerHtml = $(
                            '[map-marker-html="' +
                                marker.paczkomat_id +
                                '"]'
                        ).html();

                        if (markerHtml === 'undefined') {
                            markerHtml = $(
                                '[map-marker-html="0"]'
                            ).html();
                        }

                        let popup = L.popup({
                            maxWidth: 460,
                            offset: [-1, -32],
                        });

                        marker.bindPopup(popup);

                        marker.popup = marker.getPopup();

                        marker.popup.setContent(markerHtml);
                        marker.popup.setLatLng(marker.getLatLng());

                        var $listItem = $('[map-list-item="' + marker.paczkomat_id + '"]');
                        var position = $listItem.position()


                        marker.popup.on('add', function () {
                            zoomMinusOrPlus = 0;

                            // Sprawdzamy czy mamy dane o popup, jeżeli nie mamy to znaczy że musimy ajaxem doładować paczkomat do listy
                            var $marketHtmlDiv = $('[map-marker-html="' + marker.paczkomat_id + '"]');

                            if ($marketHtmlDiv.length > 0) {
                                marker.setIcon(pinRed);
                            } else {
                                ajaxLoadPaczkomat(marker);
                            }
                            $listItem.addClass('is-selected');

                            $('[map-paczkomaty-list]').animate({
                                scrollTop: position.top
                            }, 500);
                            
                            map.setView(marker.getLatLng(), zoom.goto);
                        });

                        marker.popup.on('remove', function () {

                            $listItem.removeClass('is-selected');

                            marker.setIcon(pinBlue);
                        });


                        markerArray[marker.paczkomat_id] = marker;
                        mapMarkers.addLayer(marker);
                    }
                });

                map.addLayer(mapMarkers);
            }

            if(center){
                map.setView(L.latLng(center.lat, center.lng), zoom.search);
            } else if(markersJson.length > 0){
                var bounds = mapMarkers.getBounds();
                map.fitBounds(bounds);
            }

            $('.js-mda-paczkomaty-map-loader').addClass('d-none');
        }
    }

    function ajaxLoadPaczkomat(marker) {
        $.ajax({
            url: ajax.url,
            data: {
                action: 'mda_paczkomaty_get_point',
                id: marker.paczkomat_id,
            },
            type: 'post',
            beforeSend: function () {
                markerHtml = $('[map-marker-html="0"]').html();
                marker.popup.setContent(markerHtml);
                $('.js-mda-paczkomaty-list-loader').removeClass('d-none');
            },
            success: function (response) {
                if (response.point) {
                    $('[map-paczkomaty-list]').prepend(response.point);

                    markerHtml = $(
                        '[map-marker-html="' + marker.paczkomat_id + '"]'
                    ).html();

                    marker.popup.setContent(markerHtml);
                }
                $('.js-mda-paczkomaty-list-loader').addClass('d-none');

                marker.setIcon(pinRed);
            },
        });
    }

    // Show on map
    $(document).on('click', '[map-list-item]', function (e) {
        e.preventDefault();
        var id = $(this).attr('map-list-item');
        goToMarker(markerArray[id]);
    });

    function goToMarker(marker) {

        paczkomatyMap.flyTo(marker.getLatLng(), zoom.goto);

        if (!marker.popup.isOpen()) {
            paczkomatyMap.openPopup(marker.popup);
        }

        $('[map-list-item].is-selected').removeClass('is-selected');
        $('[map-list-item="' + marker.paczkomat_id + '"]').addClass('is-selected');
    }

    // Wyszukiwarka
    $('[mda-paczkomaty-search-input]').on('keypress', function (e) {
        if (e.key == 'Enter') {
            paczkomatySearch($(this));

            return false;
        }
    });

    $('[mda-paczkomaty-search-input-submit]').on('click', function (e) {
        paczkomatySearch($('[mda-paczkomaty-search-input]'));
    });

    // $(document).on(
    //     'input',
    //     '[mda-paczkomaty-search-input]',
    //     _.debounce(function () {
    //         paczkomatySearch($(this));
    //     }, 500)
    // );

    var searchRequest, searchCurrentRequest;
    function paczkomatySearch(input, blockMap = true) {
        var search = input.val();
        $('[mda-paczkomaty-search-input-error]').html('');

        if (search.length < 3) {

            $('[mda-paczkomaty-search-input-error]').html(
                'Wpisz minimum 3 znaki.'
            );

        } else if (search != '') {
            searchRequest = searchCurrentRequest;

            searchCurrentRequest = $.ajax({
                url: ajax.url,
                data: {
                    action: 'mda_paczkomaty_search',
                    search: search,
                    _wpnonce: ajax.nonce,
                },
                type: 'post',
                beforeSend: function () {
                    if (searchRequest) {
                        searchRequest.abort();
                    }

                    if (blockMap) {
                        $('.js-mda-paczkomaty-map-loader').removeClass(
                            'd-none'
                        );
                    }
                    $('.js-mda-paczkomaty-list-loader').removeClass('d-none');
                },
                success: function (response) {
                    searchCurrentRequest = false;

                    if (response.list && response.points) {
                        $('[map-paczkomaty-list]').html(response.list);

                        if (blockMap) {
                            $('.js-mda-paczkomaty-map-loader').addClass('d-none');
                        }
                        $('.js-mda-paczkomaty-list-loader').addClass('d-none');

                        if(response.count < 1){
                            $('[map-paczkomaty-list]').addClass('is-empty');
                        } else {
                            $('[map-paczkomaty-list]').removeClass('is-empty');
                        }
                    }

                    setupMarkers(paczkomatyMap, response.points, response.center);
                },
            });
        }
    }

    // Strona zamówienia
    $(document).on('click', '.js-mda-paczkomaty-map-toggle', function (e) {
        e.preventDefault();

        $('#mdaPaczkomatyModal').modal('show');
    });

    $('#mdaPaczkomatyModal').on('shown.bs.modal', function () {
        if (paczkomatyMap == null) {
            paczkomatyMap = initMap('js-mda-paczkomaty-map');
        }
    });

    // Modal
    // $(document).on('click', '[map-select-paczkomat]', function (e) {
    //     e.preventDefault();

    //     var id = $(this).attr('map-select-paczkomat');

    //     selectPaczkomat(id);
    // });

    // // Select
    // function selectPaczkomat(id) {
    //     // TODO - tutaj weryfikacja czy paczkomat jest dostępny

    //     $('[name="mda_paczkomaty_paczkomat_id"]').val(id);
    //     $('#mdaPaczkomatyDetails').html($('#mda-paczkomat-' + id).html());
    //     $('#mdaPaczkomatyModal').modal('hide');
    // }
})(jQuery);
