//GA dataLayer - Usunięcie produktu z koszyka
(function ($) {
    var productName = "";
    var isRemoved = false;
    $(".js-remove-from-cart").on("click", function () {
        productName = $(this).attr("data-product_name");
        isRemoved = true;
    });
    $(document).on("updated_cart_totals", function () {
        if (isRemoved) {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: "removeFromCart",
                productName: productName,
            });
        } 
        isRemoved = false;
        $(".js-remove-from-cart").on("click", function () {
            productName = $(this).attr("data-product_name");
            isRemoved = true;
        });
    });
})(jQuery);
