(function ($) {
    if (0 < $('#js-read-pdf').length) {

        var url = $('#js-read-pdf').data('pdf'),
            pdfDoc = null,
            pageNum = 1,
            pageRendering = false,
            pageNumPending = null,
            canvas = document.getElementById('js-read-pdf'),
            ctx = canvas.getContext('2d');

        // Skalowanie w zależności od typu urządzenia - na mobile musi by 2, aby tekty były ostre!
        var scale = 1;
        if (window.innerWidth < window.innerHeight) {
            scale = 2;
        }

        /**
         * Get page info from document, resize canvas accordingly, and render page.
         * @param num Page number.
         */
        function renderPage(num) {
            pageRendering = true;
            // Using promise to fetch the page
            pdfDoc.getPage(num).then(function (page) {
                var viewport = page.getViewport({
                    scale: scale
                });
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: ctx,
                    viewport: viewport
                };
                var renderTask = page.render(renderContext);

                renderTask.promise.then(function () {
                    pageRendering = false;
                    if (pageNumPending !== null) {
                        renderPage(pageNumPending);
                        pageNumPending = null;
                    }
                });
            });

            document.getElementById('js-read-pdf-page-num').textContent = num;
        }

        /**
         * If another page rendering in progress, waits until the rendering is
         * finised. Otherwise, executes rendering immediately.
         */
        function queueRenderPage(num) {
            if (pageRendering) {
                pageNumPending = num;
            } else {
                renderPage(num);
            }
        }

        /**
         * Displays previous page.
         */
        function onPrevPage() {
            if (pageNum <= 1) {
                return;
            }
            pageNum--;
            queueRenderPage(pageNum);
        }
        document.getElementById('js-read-pdf-prev').addEventListener('click', onPrevPage);

        /**
         * Displays next page.
         */
        function onNextPage() {
            if (pageNum >= pdfDoc.numPages) {
                return;
            }
            pageNum++;
            queueRenderPage(pageNum);
        }
        document.getElementById('js-read-pdf-next').addEventListener('click', onNextPage);

        /**
         * Zoom in
         */
        function inZoomIn () {
            scale = scale + 0.25;
            renderPage(pageNum);
        }
        document.getElementById('js-read-pdf-zoom-in').addEventListener('click', inZoomIn);

        /**
         * Zoom out
         */
        function onZoomOut () {
            if (scale <= 0.25) {
                return;
            }
            scale = scale - 0.25;
            renderPage(pageNum);
        }
        document.getElementById('js-read-pdf-zoom-out').addEventListener('click', onZoomOut);

        /**
         * Asynchronously downloads PDF.
         */
        pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
            pdfDoc = pdfDoc_;
            document.getElementById('js-read-pdf-page-count').textContent = pdfDoc.numPages;

            // Initial/first page rendering
            renderPage(pageNum);
        });

        /**
         * Obsługa event'ów na klawiaturze + przesuwania palcami.
         * 
         * @version 1.1.0
         */
        $('#pdfmodal').on('shown.bs.modal', function (e) {
            $('html').css('overflow', 'hidden');
            $('#pdfmodal').keydown(function(e) {
                // Lewo
                if (e.keyCode == 37) {
                    onPrevPage();
                }

                // Prawo
                if (e.keyCode == 39) {
                    onNextPage();
                }
            });
            $("#pdfmodal").swipe({
                swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                    // Lewo
                    if (direction === 'left') {
                        onNextPage();
                    }

                    // Prawo
                    if (direction === 'right') {
                        onPrevPage();
                    }
                }
            });
        });
        $('#pdfmodal').on('hidden.bs.modal', function (e) {
            $('html').css('overflow', 'initial');
            $('#pdfmodal').off('keydown');
        });

    }
})(jQuery);
