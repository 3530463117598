(function ($) {
    var ajaxRequest = false,
        ongoingAjaxRequest = false;

    $(document).on("submit", ".js-getresponse-add-email", function (e) {
        $form = $(this);
        $response = $form.find(".js-gr-response");
        $save = $form.find(".js-gr-save-text");
        $input = $form.find(".js-gr-input");
        $response.removeClass("is-error").removeClass("is-success");

        e.preventDefault();

        data = $form.serialize();

        ajaxRequest = ongoingAjaxRequest;

        ongoingAjaxRequest = $.ajax({
            url: ajax.url,
            data: data,
            type: "post",
            beforeSend: function () {
                if (ajaxRequest) {
                    ajaxRequest.abort();
                }
                $save.html($save.attr("data-saving"));
                $input.attr("readonly", true);
            },
            success: function (response) {
                response = JSON.parse(response);
                $response.html(response.msg);
                $save.html($save.attr("data-default"));
                $input.attr("readonly", false);

                if (response.code == 200 || response.code == 202) {
                    $response.addClass("is-success");
                    $input.val("");
                    $(".js-newsletter-modal-sign").css("display", "none");
                    $(".js-newsletter-modal-sign-success").css(
                        "display",
                        "block"
                    );
                    //GA dataLayer - Zapis do newslettera - Wydaje nam się 
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: "formSubmitted",
                        formName: "Newsletter",
                        newsletterType: "Wydaje nam się",
                        subscriber: "true",
                    });
                } else {
                    $response.addClass("is-error");
                }

                ongoingAjaxRequest = false;
            },
        });
    });

    $(document).on("click", ".js-newsletter-modal-readmore-btn", function (e) {
        e.preventDefault();

        var id = $(this).attr("data-readmore");
        var $text = $(this).find(".js-newsletter-modal-readmore-btn-text");
        var oldText = $text.html();
        var $readmoreText = $('.js-readmore-text[data-readmore="' + id + '"');
        var show = $readmoreText.attr("data-show");

        $(this).attr("data-show", show == "false" ? "true" : "false");
        $text.html($text.attr("data-alt-text")).attr("data-alt-text", oldText);
        $readmoreText.attr("data-show", show == "false" ? "true" : "false");
    });

    // Maski na pola
    $(document).on("ready", function () {
        $(".js-mask-birthdate").mask("00/00/0000");
    });
})(jQuery);
