//GA dataLayer - Sprawdzenie fragmentu książki
(function ($) {
    var forms = $("form[data-form-name]");

    forms.each(function () {
        var form = $(this);
        var formName = form.attr("data-form-name");
        form.find("input[name]").on("click", function () {
            var inputName = $(this).attr("name");
            
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: "formClick",
                formName: formName,
                fieldName: inputName,
                clickCount: "1",
            });
        });
    });

})(jQuery);
