(function ($) {
    var $form = $("#js-wns-top-search-form"),
        $input = $("#js-wns-top-search-input"),
        $hints = $("#js-wns-top-search-hints"),
        $input_wrapper = $(".js-search-input-wrapper");

    $(".js-custom-scrollbar-dark").mCustomScrollbar({ alwaysShowScrollbar: 1 });

    let currentSearch = null;

    function initHints(e) {
        var that = this,
            input_text = $(that).val();

        /**
         * pokaż/ukryj na blur
         */
        $input.focus(function () {
            input_text = $input.val();
            if (input_text != "" && input_text.length > 2) {
                $hints.addClass("active");
                $input_wrapper.addClass("-active");
            }
        });

        $(document).on("click", function (e) {
            if (
                $(e.target).closest($hints).length === 0 &&
                $(e.target).closest($form).length === 0 &&
                $(e.target).closest($input).length === 0
            ) {
                $hints.removeClass("active");
                $input_wrapper.removeClass("-active");
            }
        });

        $hints.addClass("pending");

        if (!$hints.hasClass("active")) {
            $hints.addClass("active");
            $input_wrapper.addClass("-active");
        }

        if (currentSearch !== null) {
            currentSearch.abort();
        }

        $hints.addClass("pending");

        currentSearch = $.ajax({
            url: ajax.url,
            data: {
                action: "wns_search_hints",
                value: input_text,
            },
            type: "post",
            success: function (data) {
                $hints.removeClass("pending");
                data = JSON.parse(data);
                $hints.html(data.books);
                currentSearch = null;
                $(".js-custom-scrollbar-dark").mCustomScrollbar({
                    alwaysShowScrollbar: 1,
                });
            },
        });

        return false;
    }

    const switchHintCategoryTab = (e) => {
        const element = e.currentTarget;
        const target = element.dataset.target;

        $(".js-search-hint-category-link").removeClass("-active");
        $(element).addClass("-active");
        $(".search-hints-wns__books-container").removeClass("-active");
        $(`.js-wns-search-hints--${target}`).addClass("-active");
    };

    //init hints
    $input.on("keyup", _.debounce(initHints, 500));
    $input.on("click", initHints);

    $(document).on(
        "click",
        ".js-search-hint-category-link",
        switchHintCategoryTab
    );
})(jQuery);
