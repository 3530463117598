(function ($) {
    var ajaxRequest = false,
        ongoingAjaxRequest = false;
    var $cartItemsQty = $(".js-cart-items-qty");

    $("#js-add-to-cart-form [data-product-id]").on("click", function (e) {
        var $form = $("#js-add-to-cart-form"),
            product_id = $(this).attr("data-product-id");
        productName = $(this).attr("data-product_name");
        productType = $(this).attr("data-product_type");
        e.preventDefault();

        data = $form.serialize();
        data += "&product_id=" + product_id;

        if (!$form.hasClass("loading")) {
            $form
                .addClass("loading is-disabled")
                .append(
                    '<div id="js-form-loader" class="loader-container"><div class="loader"></div></div>'
                );
        }
        ajaxRequest = ongoingAjaxRequest;

        ongoingAjaxRequest = $.ajax({
            url: ajax.url,
            data: data,
            type: "post",
            beforeSend: function () {
                if (ajaxRequest) {
                    ajaxRequest.abort();
                }
                // Zmień komunikat w podsumowaniu liczby przedmiotów w koszyku
                $cartItemsQty.addClass("cart-adding is-disabled");
                $form.find(".js-add-to-cart-response").remove();
            },
            success: function (response) {
                response = JSON.parse(response);
                
                $('[name="qty"]').val(1);
                $cartItemsQty.addClass("cart-adding is-disabled");

                if (response.status == "SUCCESS") {
                
                    $cartItemsQty.text(parseInt($cartItemsQty.text()) + 1);    
                    $cartItemsQty.removeClass("cart-adding is-disabled");

                    $form.removeClass("loading is-disabled");

                    $form.append(
                        '<p class="js-add-to-cart-response add-to-cart-info add-to-cart-info--success paragraph paragraph--success paragraph--bigger">' +
                            response.message +
                            "</p>"
                    );

                    //fb pixel add to cart event tracking
                    fbq("track", "AddToCart", {
                        content_name: productName,
                        content_type: productType,
                        currency: "PLN",
                        source: "woocommerce",
                        content_ids: [`${response.product_id}`],
                        value: response.product_price,
                    });

                    //GA dataLayer - Dodanie produktu do koszyka
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: "addToCart",
                        productName: productName,
                        productType: productType,
                    });
                } else {
                    $form.append(
                        '<p class="js-add-to-cart-response add-to-cart-info add-to-cart-info--notice paragraph paragraph--notice paragraph--bigger">' +
                            response.message +
                            "</p>"
                    );
                }


                // $form.find("#js-form-loader").remove();
                $cartItemsQty.addClass("cart-adding is-disabled");

                
                $(document).trigger("mda_update_cart_qty");

                ongoingAjaxRequest = false;
            },
        });
    });
})(jQuery);
