(function ($) {
    /**
     * slider - sekcja bestsellery
     */
    var bsSlider = $('#homePreviewsSlider');
    var bsSlide = 'bestseller-slide';

    var maxSlidesToShow = bsSlider.find('.book-box').length;
    if (maxSlidesToShow < 3) return;

    bsSlider.on('init', function(){
        $('.' + bsSlide).matchHeight();
    });

    bsSlider.on('reInit', function(){
        $('.' + bsSlide).matchHeight();
    });

    bsSlider.slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<span class="slider-arrow slider-arrow--bs slider-arrow--prev"><spam class="icon-arrow-left"></spam></span>',
        nextArrow: '<span class="slider-arrow slider-arrow--bs slider-arrow--next"><spam class="icon-arrow-right"></spam></span>',
        dots: true,
        dotsClass: 'slider-dots slider-dots--bs',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
})(jQuery);