(function ($) {
    /**
     * slider produkt
     */

    var productLightbox = $('#js-book-lightbox'),
        productLightboxTrigger = $('.js-open-lightbox'),
        productSlider = $('#js-book-lightbox-slider'),
        initIndex = 0

    productLightboxTrigger.click(function() {
        initIndex = $(this).attr('data-index')
        productLightbox.modal('toggle')
    })

    productLightbox.on('shown.bs.modal', function() {
        productSlider.slick({
            prevArrow: '<span class="slider-arrow slider-arrow--bs slider-arrow--prev"><spam class="icon-arrow-left"></spam></span>',
            nextArrow: '<span class="slider-arrow slider-arrow--bs slider-arrow--next"><spam class="icon-arrow-right"></spam></span>',
            speed: 250,
        })
        productSlider.slick('slickGoTo', initIndex)
        setTimeout(function() {
            productSlider.addClass('lightbox-slider--active')
            $('.lightbox-slider-body').addClass('lightbox-slider-body--active')
        }, 250)
    })

    productLightbox.on('hidden.bs.modal', function() {
        productSlider.slick('unslick')
        productSlider.removeClass('lightbox-slider--active')
        $('.lightbox-slider-body').removeClass('lightbox-slider-body--active')
    })

})(jQuery);