(function ($) {
    var $cartItemsQty = $(".js-cart-items-qty");

    $(window).on("load", function () {
        if ($cartItemsQty.length > 0) {
            // Zaktualizuj liczbę produktów koszyku
            $.ajax({
                type: "post",
                url: ajax.url,
                data: {
                    action: "mda_get_cart_items_qty",
                },
                success: function (response) {
                    response = $.parseJSON(response);
                    $cartItemsQty.removeClass("cart-adding");
                    $cartItemsQty.html(response.qty);
                },
            });
        }
    });

    if ($cartItemsQty.length > 0) {
        var $btnStateNormal = $(".js-ajax-add-to-cart:not(.is-disabled)");

        if ($btnStateNormal.length > 0) {
            // Obsługa dodaj do koszyka
            $btnStateNormal.on("click", function () {
                // Zmień komunikat w podsumowaniu liczby przedmiotów w koszyku
                $cartItemsQty.addClass("cart-adding");

                // Zablokuj przyciski w czasie dodawania
                $btnStateNormal.addClass("is-disabled");

                // Zmień tekst na przycisku dodawania
                $(this).html($(this).attr("data-text-loading"));
            });
        }
        // GA dataLayer - Dodanie produktu do koszyka
        $(document).on("added_to_cart", function (a, b, c, d) {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: "addToCart",
                productName: d.data("product_name"),
                productType: d.data("product_type"),
            });
        });

        $(document).on(
            "added_to_cart updated_wc_div mda_update_cart_qty",
            function () {
                var $btnStateAdded = $(".js-ajax-add-to-cart.added"),
                    $btnStateDisabled = $(".js-ajax-add-to-cart.is-disabled");

                // Zmień na tekst na buttonie "Dodaj do koszyka" na "Dodane do koszyka"
                $btnStateAdded.html($btnStateAdded.attr("data-text-added"));

                // Zaktualizuj liczbę produktów koszyku
                $.ajax({
                    type: "post",
                    url: ajax.url,
                    data: {
                        action: "mda_get_cart_items_qty",
                    },
                    success: function (response) {
                        response = $.parseJSON(response);
                        $cartItemsQty.removeClass("cart-adding");
                        $cartItemsQty.html(response.qty);

                        // Odblokuj inne przyciski dodawania do koszyka
                        if ($btnStateDisabled.length > 0) {
                            $btnStateDisabled.removeClass("is-disabled");
                        }

                        // Zmień tekst na przycisku dodawania do koszyka
                        if ($btnStateAdded.length > 0) {
                            $btnStateAdded
                                .html($btnStateAdded.attr("data-text-default"))
                                .removeClass("added");
                        }
                    },
                });
            }
        );
    }
})(jQuery);
