(function ($) {
    $(document).on("click", ".js-copy-link-to-comment", (e) => {
        const commentID = e.currentTarget.dataset.commentId;
        const currentURL = window.location.href;
        let baseURL = "";

        if (currentURL.indexOf("#comment") !== -1) {
            baseURL = currentURL.substring(0, currentURL.indexOf("#comment"));
        } else {
            baseURL = currentURL;
        }

        const url = `${baseURL}#comment-${commentID}`;

        navigator.clipboard.writeText(url);
    });
})(jQuery);
