(function ($) {
    //Odtawrzacz YT
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    var player;

    $(document).on('click', '.open-movie-modal', function (e) {
        e.preventDefault();

        //otwórz modal
        $('#moviemodal').modal({
            backdrop: 'static'
        }, 'toggle');

        var video_url = $(this).data('show-video');
        var video_title = $(this).data('video-title');

        if (typeof video_url == 'string') {
            //znajdź video_id
            var video_id = $(this).data('show-video').split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }

            $('#modalMovieTitle').text(video_title);
            //start filmu
            processPlayer('movieContainer', video_id);
        }
    });

    //zamknięcie modala
    $('#moviemodal').on('hidden.bs.modal', function (e) {
        player.stopVideo();
        player.destroy()
        $('#modalMovieTitle').text('');
    })

    function processPlayer(container, video_id) {
        player = new YT.Player(container, {
            videoId: video_id,
            playerVars: {rel: 0},
            events: {
                'onReady' : onPlayerReady
            }
        });
    
        function onPlayerReady(event) {
            player.playVideo();
        };
    }
})(jQuery);