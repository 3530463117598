(function ($) {
    var map;
    function initmap() {
        var contactMap = document.getElementById('contactMap');
        var pin = contactMap.dataset.pin;
        var lat = contactMap.dataset.lat;
        var lng = contactMap.dataset.lng;

        map = new L.Map('contactMap');

        var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

        var osm = new L.TileLayer(osmUrl, {minZoom: 8, maxZoom: 24, attribution: osmAttrib});		

        map.setView(new L.LatLng(lat, lng),18);
        map.addLayer(osm);
        map.scrollWheelZoom.disable();

        if ($(window).width() < 1025) {
            map.touchZoom.disable();
            map.dragging.disable();
        }
        var IconImage = L.icon({
            iconUrl: pin,
            iconSize: [64, 75],
        });

        var marker = L.marker([lat, lng], {icon: IconImage}).addTo(map);
    }

    var contactMap = document.getElementById('contactMap');

    if (contactMap !== null) {
        initmap();
    }

})(jQuery);