(function ($) {
    /**
     * slider na stronie głównej
     */
    var homeSlider = $("#homeSlider");

    homeSlider.on("init", function () {
        $(".home-slider-container").css("overflow", "initial");
    });

    homeSlider.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow:
            '<span class="slider-arrow slider-arrow--home slider-arrow--prev"><spam class="icon-arrow-left"></spam></span>',
        nextArrow:
            '<span class="slider-arrow slider-arrow--home slider-arrow--next"><spam class="icon-arrow-right"></spam></span>',
        dots: true,
        dotsClass: "slider-dots slider-dots--home",
        appendArrows: $("#homeArrowContainer"),
        appendDots: $("#homeArrowContainer"),
        autoplay: true,
        autoplaySpeed: 5000,
    });

    /**
     * slider - sekcja bestsellery
     */
    var bsSlider = $("#bestsellersSlider");
    var bsSlide = "bestseller-slide";

    bsSlider.on("init", function () {
        $("." + bsSlide).matchHeight();
    });

    bsSlider.on("reInit", function () {
        $("." + bsSlide).matchHeight();
    });

    bsSlider.slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow:
            '<span class="slider-arrow slider-arrow--bs slider-arrow--prev"><spam class="icon-arrow-left"></spam></span>',
        nextArrow:
            '<span class="slider-arrow slider-arrow--bs slider-arrow--next"><spam class="icon-arrow-right"></spam></span>',
        dots: true,
        dotsClass: "slider-dots slider-dots--bs",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    /**
     * slider produkt
     */

    var productSlider = $("#js-product-slider");
    var productSliderNav = $(".js-product-slider-nav");

    productSlider.slick({
        arrows: false,
        fade: true,
        speed: 200,
    });

    productSliderNav.click(function () {
        productSliderNav.each(function () {
            $(this)
                .find(".product-slider__nav-item")
                .removeClass("product-slider__nav-item--active");
        });
        productSlider.slick("slickGoTo", $(this).data("target"));
        $(this)
            .find(".product-slider__nav-item")
            .addClass("product-slider__nav-item--active");
    });

    /**
     * slider autprzy
     */

    var authorsSlider = $("#js-authors-slider");

    authorsSlider.slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow:
            '<span class="slider-arrow slider-arrow--authors slider-arrow--prev"><spam class="icon-angel-left"></spam></span>',
        nextArrow:
            '<span class="slider-arrow slider-arrow--authors slider-arrow--next"><spam class="icon-angel-right"></spam></span>',
    });

    /**
     * slider - sekca inne strony w menu mobilnym
     */

    var handleOtherSitesSlider = function () {
        var sitesSlider = $("#otherSitesSlider");
        const isContentSite = sitesSlider.hasClass("-content-site");
        var isInitialized = false;

        if (sitesSlider.length) {
            if (isContentSite) {
                $(".navbar-toggler").on("click", function () {
                    if (!isInitialized) {
                        setTimeout(function () {
                            sitesSlider.slick({
                                infinite: true,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                arrows: false,
                                responsive: [
                                    {
                                        breakpoint: 1200,
                                        settings: {
                                            infinite: true,
                                            slidesToShow: 6,
                                            slidesToScroll: 1,
                                            arrows: false,
                                            centerMode: true,
                                        },
                                    },
                                    {
                                        breakpoint: 560,
                                        settings: {
                                            infinite: true,
                                            slidesToShow: 3,
                                            slidesToScroll: 1,
                                            arrows: false,
                                        },
                                    },
                                ],
                            });
                        }, 100);
                        isInitialized = true;
                    }
                });
            } else {
                sitesSlider.slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                infinite: true,
                                slidesToShow: 6,
                                slidesToScroll: 1,
                                arrows: false,
                                centerMode: true,
                            },
                        },
                        {
                            breakpoint: 560,
                            settings: {
                                infinite: true,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                arrows: false,
                            },
                        },
                    ],
                });
            }
        }
    };
    handleOtherSitesSlider();

    /**
     * slider na stronie autora 'custom-products-slider'
     */
    $(document).ready(function () {
        const $authorCustomBooksSliderWrapper = $(
            "#js-author-custom-book-loop"
        );

        $authorCustomBooksSliderWrapper.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000,
            rows: 1,
            prevArrow:
                '<span class="slider-arrow slider-arrow--authors slider-arrow--prev"><spam class="icon-angel-left"></spam></span>',
            nextArrow:
                '<span class="slider-arrow slider-arrow--authors slider-arrow--next"><spam class="icon-angel-right"></spam></span>',
        });
    });

    /**
     * Slider sugerowanych produktów
     */
    const sugestedProductsSlider = $(".js-suggested-product-slider");
    const sugestedProductsSlide = "js-suggested_products_slide";

    sugestedProductsSlider.on("init", function () {
        $(`.${sugestedProductsSlide}`).matchHeight();
    });

    sugestedProductsSlider.on("reInit", function () {
        $(`.${sugestedProductsSlide}`).matchHeight();
    });

    sugestedProductsSlider.slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow:
            '<span class="slider-arrow slider-arrow--bs slider-arrow--prev"><spam class="icon-arrow-left"></spam></span>',
        nextArrow:
            '<span class="slider-arrow slider-arrow--bs slider-arrow--next"><spam class="icon-arrow-right"></spam></span>',
        dots: true,
        dotsClass: "slider-dots slider-dots--bs",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
})(jQuery);
