(function ($) {
    $overlay = $("#js-menu-overlay");

    $("#navbarNavMenu").on("show.bs.collapse", function () {
        $overlay.addClass("is-shown");
        $(".js-site-header-nav").addClass("overflow-x-auto");
    });

    $("#navbarNavMenu").on("hidden.bs.collapse", function () {
        $overlay.removeClass("is-shown");
    });

    $("#js-menu-overlay").on("click", function () {
        $("#navbarNavMenu").collapse("toggle");
    });

    const toggleSiteScrolling = () => {
        setTimeout(() => {
            const isMenuOpen = $(".js-navbar-toggler").hasClass("collapsed");
            const isSearchOpen = $(".js-search-toggler ").hasClass("collapsed");

            if (!$("body").hasClass("-scroll-disbaled")) {
                $("body").addClass("-scroll-disbaled");

                const topScroll = document.documentElement.scrollTop;

                window.onscroll = () => {
                    window.scrollTo(0, topScroll);
                };
            } else if (
                $("body").hasClass("-scroll-disbaled") &&
                isMenuOpen &&
                isSearchOpen
            ) {
                $("body").removeClass("-scroll-disbaled");
                window.onscroll = () => {};
            }
        }, 250);
    };

    $(".js-search-toggler").on("click", () => toggleSiteScrolling());

    $(".js-navbar-toggler").on("click", () => toggleSiteScrolling());

    $("body").on("click", ".js-menu-dropdown", function (e) {
        const isMenuOpen = !$(".js-navbar-toggler").hasClass("collapsed");
        const isSearchOpen = !$(".js-search-toggler ").hasClass("collapsed");
        const currentElement = e.target;
        const preventClasses = [
            "js-main-menu",
            "js-menu-social-media",
            "js-top-search-hints",
            "js-search-input-wrapper",
        ];
        let isAnyParentHasPreventClass = false;

        preventClasses.forEach((classItem) => {
            const parentHasClass =
                currentElement.closest(`.${classItem}`) !== null;

            if (!parentHasClass) return;

            isAnyParentHasPreventClass = true;
        });

        if (isMenuOpen && !isAnyParentHasPreventClass) {
            $(".js-navbar-toggler").trigger("click");
        } else if (isSearchOpen && !isAnyParentHasPreventClass) {
            $(".js-search-toggler ").trigger("click");
        }
    });
})(jQuery);
