(function ($) {
  var scrollDirection = null;
  var lastScrollTop = 0;
  var siteHeader = $("#siteHeader");

  $(window).scroll(function (event) {
    var st = $(this).scrollTop(),
      scroll = $(window).scrollTop();

    if (scroll >= 75) {
      if (st > lastScrollTop) {
        if (scrollDirection != 1) {
          scrollDirection = 1;
          siteHeader.addClass("site-header--scroll");
        }
      } else if (st < lastScrollTop) {
        if (scrollDirection != 0) {
          scrollDirection = 0;
          siteHeader.removeClass("site-header--scroll");
        }
      }
    } else {
      siteHeader.removeClass("site-header--scroll");
    }
    lastScrollTop = st;
  });
})(jQuery);
