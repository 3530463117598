(function ($) {
    var selectItemName = '.js-select-rating', 
        selectItem = $(selectItemName), 
        selectInput = $('#js-rating-select');

    selectItem.hover(function() {
        $(this).prevAll().addClass('select-rating__star--hovered');
    })

    selectItem.mouseout(function() {
        selectItem.each(function(){
            $(this).removeClass('select-rating__star--hovered');
        })
    })

    selectItem.click(function() {
        selectItem.each(function(){
            $(this).removeClass('select-rating__star--selected');
        })
        $(this).addClass('select-rating__star--selected').prevAll().addClass('select-rating__star--selected');
        
        var note = $(this).data('rate-value');
        selectInput.val(note);
    })

    

})(jQuery);