/**
 *
 * @param {object} rangeSlider slider container jquery object
 * @param {object} rangeSliderInputMin input min jquery object
 * @param {object} rangeSliderInputMax input max jquery object
 * @param {int} rangeMin min value
 * @param {int} rangeMax max value
 */

(function ($) {
    /**
     * price range
     */
    var rangeSlider = $("#js-price-slider-range");
    var rangeSliderInputMin = $("#js-amount-min");
    var rangeSliderInputMax = $("#js-amount-max");
    var rangeMin = 0;
    var rangeMax = parseInt(rangeSliderInputMax.attr("data-max")) + 1;

    $(document).on("ready", function () {
        priceRangeInputs(
            rangeSlider,
            rangeSliderInputMin,
            rangeSliderInputMax,
            parseInt(rangeMin),
            parseInt(rangeMax)
        );
    });

    function priceRangeInputs(
        rangeSlider,
        rangeSliderInputMin,
        rangeSliderInputMax,
        rangeMin,
        rangeMax
    ) {
        rangeSlider.slider({
            range: true,
            min: rangeMin,
            max: rangeMax,
            values: [rangeSliderInputMin.val(), rangeSliderInputMax.val()],
            slide: function (event, ui) {
                rangeSliderInputMin.val(ui.values[0]);
                rangeSliderInputMax.val(ui.values[1]);
            },
            stop: function (event, ui) {
                if ($("#js-products-form").length > 0) {
                    $("#js-products-form").trigger("submit");
                }
                if ($("#js-products-form-noajax").length > 0) {
                    $("#js-products-form-noajax").trigger("submit");
                }

                if (window.innerWidth < 1024) {
                    $(".js-book-filter-toggler").trigger("click");
                }
            },
        });
        rangeSliderInputMin.val(rangeSlider.slider("values", 0));
        rangeSliderInputMax.val(rangeSlider.slider("values", 1));

        rangeSliderInputMin.change(function () {
            rangeSlider.slider("option", "values", [
                rangeSliderInputMin.val(),
                rangeSliderInputMax.val(),
            ]);

            if (rangeSliderInputMin.val() < rangeMin) {
                rangeSliderInputMin.val(rangeMin);
                rangeSliderInputMin.trigger("change");
            }

            if (rangeSliderInputMin.val() > rangeMax) {
                rangeSliderInputMin.val(rangeSlider.slider("values", 1));
                rangeSliderInputMin.trigger("change");
            }

            if (rangeSliderInputMin.val() > rangeSlider.slider("values", 1)) {
                rangeSliderInputMin.val(rangeSlider.slider("values", 1));
                rangeSliderInputMin.trigger("change");
            }
        });

        rangeSliderInputMax.change(function () {
            rangeSlider.slider("option", "values", [
                rangeSliderInputMin.val(),
                rangeSliderInputMax.val(),
            ]);

            if (rangeSliderInputMax.val() < rangeMin) {
                rangeSliderInputMax.val(rangeSlider.slider("values", 0));
                rangeSliderInputMax.trigger("change");
            }

            if (rangeSliderInputMax.val() > rangeMax) {
                rangeSliderInputMax.val(rangeMax);
                rangeSliderInputMax.trigger("change");
            }

            if (rangeSliderInputMax.val() < rangeSlider.slider("values", 0)) {
                rangeSliderInputMax.val(rangeSlider.slider("values", 0));
                rangeSliderInputMax.trigger("change");
            }
        });
    }
})(jQuery);
