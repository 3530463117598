(function ($) {
    var ajaxRequest = false,
        ongoingAjaxRequest = false;

    $("#js-ajax-product-reviews").submit(function (e) {
        $form = $(this);

        e.preventDefault();

        data = $form.serialize();
        if (!$form.hasClass("loading")) {
            $form
                .addClass("loading")
                .append(
                    '<div id="js-form-loader" class="loader-container"><div class="loader"></div></div>'
                );
        }

        // For GA dataLayer script
        var productName = $(this).attr("data-product-name");

        ajaxRequest = ongoingAjaxRequest;

        ongoingAjaxRequest = $.ajax({
            url: ajax.url,
            data: data + "&action=mda_ajax_product_reviews",
            type: "post",
            beforeSend: function () {
                if (ajaxRequest) {
                    ajaxRequest.abort();
                }
                $(".js-odm-cart-header-qty").html("").addClass("loading");
                $form.find(".js-add-to-cart-response").remove();
            },
            success: function (response) {
                response = JSON.parse(response);
                $('[name="qty"]').val(1);

                if (response.status == "SUCCESS") {
                    $form.append(
                        '<p class="js-add-to-cart-response paragraph paragraph--success paragraph--bigger add-to-cart-info add-to-cart-info--success" style="margin-top: 15px;">' +
                            response.message +
                            "</p>"
                    );
                    //GA dataLayer Dodanie opinii o produkcie
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: "productAction",
                        action: "Dodaj opinię",
                        productName: productName,
                    });
                } else {
                    $form.append(
                        '<p class="js-add-to-cart-response paragraph paragraph--notice paragraph--bigger add-to-cart-info add-to-cart-info--notice" style="margin-top: 15px;">' +
                            response.message +
                            "</p>"
                    );
                }

                $form.removeClass("loading");
                $form.find("#js-form-loader").remove();

                $(document).trigger("mda_update_cart");

                ongoingAjaxRequest = false;
            },
        });
    });
})(jQuery);
