//GA dataLayer - Kliknięcie produktu na liście Powiązane produkty
(function ($) {
    $(".js-related-product-link").on("click", function () {
        productName = $(this).attr("data-product_name");

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: "relatedBooks",
            productName: productName,
        });
    });
})(jQuery);

