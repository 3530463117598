(function ($) {
    const getDuration = (src) => {
        return new Promise(function (resolve) {
            let audio = new Audio();
            $(audio).on("loadedmetadata", function () {
                resolve(audio.duration);
            });
            audio.src = src;
        });
    };

    const getTimeCodeFromNum = (num) => {
        let seconds = parseInt(num);
        let minutes = parseInt(seconds / 60);
        seconds -= minutes * 60;
        const hours = parseInt(minutes / 60);
        minutes -= hours * 60;

        if (hours === 0)
            return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
        return `${String(hours).padStart(2, 0)}:${minutes}:${String(
            seconds % 60
        ).padStart(2, 0)}`;
    };

    const audioPlayers = document.querySelectorAll(".js-audio-player");

    audioPlayers.forEach((player) => {
        const audio = player.querySelector(".js-audio-player-html");
        const source = player.querySelector(
            ".js-audio-player-html .js-audio-player-source"
        );
        const fileSrc = source.src;

        getDuration(fileSrc).then((length) => {
            const progressBar = player.querySelector(
                ".js-audio-player-progress"
            );
            const playerCurrentTime = player.querySelector(
                ".js-audio-player-time .js-audio-player-current"
            );
            const playerDuration = player.querySelector(
                ".js-audio-player-time .js-audio-player-length"
            );
            const timeline = player.querySelector(".js-audio-player-timeline");
            const percentageSliderVolume = player.querySelector(
                ".js-audio-player-controls .js-audio-player-volume-percentage"
            );

            playerDuration.textContent = getTimeCodeFromNum(length);

            //change time on timeline
            const changeTimeMouse = (e) => {
                const timelineWidth = window.getComputedStyle(timeline).width;
                const timeToSeek =
                    (e.offsetX / parseInt(timelineWidth)) * length;
                audio.currentTime = timeToSeek;
            };

            const changeTimeTouch = (e) => {
                const timelineWidth = window.getComputedStyle(timeline).width;
                const timeLineRect = timeline.getBoundingClientRect();
                const fingerPosition = e.touches[0].clientX;
                const minFingerPosition = timeLineRect.left;
                const maxFingerPosition =
                    timeLineRect.left + timeline.clientWidth;
                let timeToSeek = 0;

                if (
                    fingerPosition >= minFingerPosition &&
                    fingerPosition <= maxFingerPosition
                ) {
                    timeToSeek =
                        ((fingerPosition - minFingerPosition) /
                            parseInt(timelineWidth)) *
                        length;
                } else if (fingerPosition < minFingerPosition) {
                    timeToSeek = 0;
                } else {
                    timeToSeek = length;
                }

                audio.currentTime = timeToSeek;
            };

            //change time
            //mouse
            timeline.addEventListener("click", changeTimeMouse);

            timeline.addEventListener("mouseup", () => {
                timeline.removeEventListener("mousemove", changeTimeMouse);
            });

            timeline.addEventListener("mousedown", () => {
                timeline.addEventListener("mousemove", changeTimeMouse);
            });

            //touch
            timeline.addEventListener("touchend", () => {
                timeline.removeEventListener("touchmove", changeTimeTouch);
            });

            timeline.addEventListener("touchstart", () => {
                timeline.addEventListener("touchmove", changeTimeTouch);
            });

            //volume slider
            const volumeSlider = player.querySelector(
                ".js-audio-player-controls .js-audio-player-volume-slider"
            );

            const changeVolumeMouse = (e) => {
                const sliderWidth = window.getComputedStyle(volumeSlider).width;
                const newVolume = e.offsetX / parseInt(sliderWidth);

                audio.volume = newVolume;
                percentageSliderVolume.style.width = newVolume * 100 + "%";
            };

            const changeVolumeTouch = (e) => {
                const sliderWidth = window.getComputedStyle(volumeSlider).width;
                const volumeLineRect = volumeSlider.getBoundingClientRect();
                const fingerPosition = e.touches[0].clientX;
                const minFingerPosition = volumeLineRect.left;
                const maxFingerPosition =
                    volumeLineRect.left + volumeSlider.clientWidth;
                let newVolume = 1;

                if (
                    fingerPosition >= minFingerPosition &&
                    fingerPosition <= maxFingerPosition
                ) {
                    newVolume =
                        (fingerPosition - minFingerPosition) /
                        parseInt(sliderWidth);
                } else if (fingerPosition < minFingerPosition) {
                    newVolume = 1;
                }

                audio.volume = newVolume >= 0 ? newVolume : 0;
                percentageSliderVolume.style.width = newVolume * 100 + "%";
            };

            //mouse
            volumeSlider.addEventListener("click", changeVolumeMouse);

            volumeSlider.addEventListener("mouseup", () => {
                volumeSlider.removeEventListener(
                    "mousemove",
                    changeVolumeMouse
                );
            });

            volumeSlider.addEventListener("mousedown", () => {
                volumeSlider.addEventListener("mousemove", changeVolumeMouse);
            });

            //touch
            volumeSlider.addEventListener("touchend", () => {
                volumeSlider.removeEventListener(
                    "touchmove",
                    changeVolumeTouch
                );
            });

            volumeSlider.addEventListener("touchstart", () => {
                volumeSlider.addEventListener("touchmove", changeVolumeTouch);
            });

            //volume slider toggle
            const volumeBtn = player.querySelector(
                ".js-audio-player-volume-button"
            );
            const volumeSliderWrapper = player.querySelector(
                ".js-audio-player-volume-control-wrapper"
            );
            const volumeBtnWrapper = player.querySelector(
                ".js-audio-player-volume-container"
            );
            volumeBtn.addEventListener("click", () => {
                if (volumeBtn.classList.contains("-is-active")) {
                    volumeBtn.classList.remove("-is-active");
                    volumeSliderWrapper.classList.remove("-is-active");
                    volumeBtnWrapper.classList.remove("-is-active");
                } else {
                    volumeBtn.classList.add("-is-active");
                    volumeSliderWrapper.classList.add("-is-active");
                    volumeBtnWrapper.classList.add("-is-active");
                }
            });

            //play/ pause btn
            const playBtn = player.querySelector(
                ".js-audio-player-controls .js-audio-player-toggle-play"
            );
            playBtn.addEventListener(
                "click",
                () => {
                    if (audio.paused) {
                        player
                            .querySelector(".js-audio-player-btn")
                            .classList.add("-is-active");
                        playBtn.classList.remove("icon-audio-play");
                        playBtn.classList.add("icon-pause");
                        audio.play();
                    } else {
                        player
                            .querySelector(".js-audio-player-btn")
                            .classList.remove("-is-active");
                        playBtn.classList.remove("icon-pause");
                        playBtn.classList.add("icon-audio-play");
                        audio.pause();
                    }
                },
                false
            );

            //player progress bar
            setInterval(() => {
                progressBar.style.width =
                    (audio.currentTime / length) * 100 + "%";

                playerCurrentTime.textContent = getTimeCodeFromNum(
                    audio.currentTime
                );

                if (audio.currentTime === length) {
                    player
                        .querySelector(".js-audio-player-btn")
                        .classList.remove("-is-active");
                    playBtn.classList.remove("icon-pause");
                    playBtn.classList.add("icon-audio-play");
                    audio.pause();
                }
            }, 500);
        });
    });
})(jQuery);
